import { RootState } from "@/Redux/store";
import Image from "next/image";
import React from "react";
import { useSelector } from "react-redux";

const FullScreenLoader = ({ loadingCustom }: { loadingCustom?: boolean }) => {
  const { loading } = useSelector((state: RootState) => state.loader);
  return !!loading || !!loadingCustom ? (
    <div className={`main-loader`}>
      {/* <div className="snippet" data-title="dot-flashing">
                    <div className="stage">
                        <div className="dot-flashing"></div>
                    </div>
                </div> */}
      <div className="loader">
        <Image alt="Loader" src={"/assets/OGlogo.svg"} height={65} width={65} />
      </div>
    </div>
  ) : null;
};

export default FullScreenLoader;
