import axiosMethods from "@/Services/https/https";
export const getUpcomingInterviewerInterviewsListApi = async (values: any) => {
  //eslint-disable-line
  const getUpcomingInterviewerInterviewsListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getUpcomingInterviewerInterviewsListData;
};

export const getCompletedInterviewerInterviewsListsApi = async (values: any) => {
  //eslint-disable-line
  const getCompletedInterviewerInterviewsListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getCompletedInterviewerInterviewsListData;
};
export const getNewInterviewerInterviewsListApi = async (values: any) => {
  //eslint-disable-line
  const getNewInterviewerInterviewsListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getNewInterviewerInterviewsListData;
};

export const getPendingInterviewerInterviewsListsApi = async (values: any) => {
  //eslint-disable-line
  const getPendingInterviewerInterviewsListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getPendingInterviewerInterviewsListData;
};

// When interviewer Apply their interviews from New interviews lists
export const applyInterview = async (values: { bodyData: { interviewId: string; interviewerId: string } }) => {
  //eslint-disable-line
  const data: { code: number; message: string } = await axiosMethods.post(`/interview`, values?.bodyData, {
    headers: {
      Resource: "join-interview-by-interviewer",
      ProfileRole: "Interviewer",
    },
  });

  return data;
};

// When interviewer reject their interviews from New interviews lists
export const rejectNewInterviewApi = async (values: any) => {
  //eslint-disable-line
  const rejectNewInterview: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return rejectNewInterview;
};

//This is for multiple selection of candidate for either publish or republish or removed
export const publishRepublishRemovedApi = async (values: any) => {
  //eslint-disable-line
  const publishRepublishRemovedData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return publishRepublishRemovedData;
};

//This is for getting interviewDetails
export const getInterviewDetailsApi = async (values: any) => {
  //eslint-disable-line
  const getInterviewDetalsData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getInterviewDetalsData;
};
/**
 * Create Account of interviwer in strip
 */
interface IStripeAccountCrete {
  bodyData: {
    interviewerId: string;
    country: string;
  };
  resource: string;
  role: string;
}

interface IStripeAccountUpdate {
  bodyData: {
    stripeAccountId: string;
    // country: string;
  };
  resource: string;
  role: string;
}
export const createStripeAccInterviwerApi = async (values: IStripeAccountCrete) => {
  const createInterviwerStripe = await axiosMethods.post(`/payment`, values.bodyData, {
    headers: {
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return createInterviwerStripe;
};

export const updateStripeAccInterviwerApi = async (values: IStripeAccountUpdate) => {
  const createInterviwerStripe = await axiosMethods.post(`/interviewer`, values.bodyData, {
    headers: {
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return createInterviwerStripe;
};
