import { socket } from "@/Services/sockets/socket";
import { EVENT_EMIT_ACTIONS } from "../SocketsEvents/socketEvents";
import { jsonData } from "@/staticData";

// Chat Authentication
export const authincateUser = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.AUTHENTICATE_USER,
  });

  socket && socket?.send(dataToSend);
};
// Emit to get Chatrooms With Respective Tabs
export const getChatRooms = (data: any) => {
  //eslint-disable-line

  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    userId: data.message?.userId,
    roomId: "",
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.GET_CHATROOMS_BY_USER,
  });

  socket && socket?.send(dataToSend);
};
//Emit to get Chatrooms when User Search
export const searchChatRooms = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    userId: data.message?.userId,
    roomId: "",
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.SEARCH_CHATROOMS_BY_USER,
  });

  socket && socket?.send(dataToSend);
};
///Emit to Get all messages when user click on any room card
export const getChatRoomsMessages = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.GET_MESSAGES_BY_CHATROOM,
  });

  socket && socket?.send(dataToSend);
};
//When user send message
export const postChatMessage = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.POST_MESSAGE,
  });

  socket && socket?.send(dataToSend);
};
//When user clicks on Archive
export const changedToArchiveRoom = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.ARCHIVE_ROOM_EMIT,
  });

  socket && socket?.send(dataToSend);
};
//When user clicks on UnArchive
export const changedToActiveRoom = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.UNARCHIVE_ROOM_EMIT,
  });

  socket && socket?.send(dataToSend);
};
//When user want to send file
export const postAttachmentChatMessage = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.POST_ATTACHMENT_MESSAGE,
  });

  socket && socket?.send(dataToSend);
};
//This is for when user want the updated room
export const updateChatRoomEmit = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.UPDATE_CHATROOMS_EMIT,
  });

  socket && socket?.send(dataToSend);
};
//When user creats One to One room first time in his own
export const createSingleChatroom = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.CREATE_SINGLE_CHATROOM,
  });

  socket && socket?.send(dataToSend);
};
//When user wants to delete the chat from Inactive list/s
export const deleteChat = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.DELETE_CHAT_INACTIVE,
  });

  socket && socket?.send(dataToSend);
};
//When user wants to delete the conversations from Personal list/s
export const deleteChatConversation = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.DELETE_SINGLE_ROOM_CONVERSATION,
  });

  socket && socket?.send(dataToSend);
};
//When user wants to Mute the notifications of particular chatroom
export const muteChatNotification = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.MUTE_ROOM_NOTIFICATION,
  });

  socket && socket?.send(dataToSend);
};
//When user wants to Unmute the notifications of particular chatroom
export const unMuteChatNotification = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.UNMUTE_ROOM_NOTIFICATION,
  });

  socket && socket?.send(dataToSend);
};

//When user wants to read the Unread messages if they have
export const seenMessage = (data: any) => {
  //eslint-disable-line
  const dataToSend: any = JSON.stringify({
    //eslint-disable-line
    ...jsonData,
    message: data.message,
    topic: EVENT_EMIT_ACTIONS.SEEN_MESSAGE,
  });

  socket && socket?.send(dataToSend);
};
