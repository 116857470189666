import { createSlice } from "@reduxjs/toolkit";
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  professionalChatRoomsList: [],
  personalChatRoomsList: [],
  inActiveChatRoomsList: [],
  archiveChatRoomsList: [],
  searchChatRoomsList: [],
  searchValueStored: "",
  activeChatRoom: null,
  loaderForRoomList: false,
  roomDetails: {},
  deleteInactiveChatModel: false,
};

export const chatRoomSlice = createSlice({
  name: "chatRoomSlice",
  initialState,
  reducers: {
    setProfessionalChatRoomsList: (state, action) => {
      let mergedList;
      if (action.payload.list) {
        // Merge list with hasMore
        mergedList =
          state.professionalChatRoomsList.list?.length > 0
            ? [...state.professionalChatRoomsList.list, ...action.payload.list]
            : action.payload.list;
      } else {
        // Add single room if no hasMore or list exists
        mergedList = state.professionalChatRoomsList.list
          ? [action.payload, ...state.professionalChatRoomsList.list]
          : [action.payload];
      }

      const deduplicatedList = mergedList
        .reduce((acc: any, room: any) => {
          if (!acc.some((r: any) => r.roomId === room.roomId)) acc.push(room);
          return acc;
        }, [])
        .sort((a: any, b: any) => new Date(b.updatedDate).getTime() - new Date(a.updatedDate).getTime());

      state.professionalChatRoomsList = {
        list: deduplicatedList,
        hasMore: action.payload.hasMore,
      };
    },
    setPersonalChatRoomsList: (state, action) => {
      let mergedList;

      if (action.payload.list) {
        // Merge list with hasMore
        mergedList =
          state.personalChatRoomsList?.list?.length > 0
            ? [...state.personalChatRoomsList.list, ...action.payload.list]
            : action.payload.list;
      } else {
        // Add single room if no hasMore or list exists
        mergedList = state.personalChatRoomsList.list
          ? [action.payload, ...state.personalChatRoomsList.list]
          : [action.payload];
      }

      const deduplicatedList = mergedList
        .reduce((acc: any, room: any) => {
          if (!acc.some((r: any) => r.roomId === room.roomId)) acc.push(room);
          return acc;
        }, [])
        .sort((a: any, b: any) => new Date(b.updatedDate).getTime() - new Date(a.updatedDate).getTime());

      state.personalChatRoomsList = {
        list: deduplicatedList,
        hasMore: action.payload.hasMore,
      };
    },
    setInactiveChatRoomsList: (state, action) => {
      let mergedList;

      if (action.payload.list) {
        // Merge list with hasMore
        mergedList =
          state.inActiveChatRoomsList?.list?.length > 0
            ? [...state.inActiveChatRoomsList.list, ...action.payload.list]
            : action.payload.list;
      } else {
        // Add single room if no hasMore or list exists
        mergedList = state.inActiveChatRoomsList.list
          ? [action.payload, ...state.inActiveChatRoomsList.list]
          : [action.payload];
      }

      const deduplicatedList = mergedList
        .reduce((acc: any, room: any) => {
          if (!acc.some((r: any) => r.roomId === room.roomId)) acc.push(room);
          return acc;
        }, [])
        .sort((a: any, b: any) => new Date(b.updatedDate).getTime() - new Date(a.updatedDate).getTime());

      state.inActiveChatRoomsList = {
        list: deduplicatedList,
        hasMore: action.payload.hasMore,
      };
    },
    setArchiveChatRoomsList: (state, action) => {
      let mergedList;

      if (action.payload.list) {
        // Merge list with hasMore
        mergedList =
          state.archiveChatRoomsList.list?.length > 0
            ? [...state.archiveChatRoomsList.list, ...action.payload.list]
            : action.payload.list;
      } else {
        // Add single room if no hasMore or list exists
        mergedList = state.archiveChatRoomsList.list
          ? [action.payload, ...state.archiveChatRoomsList.list]
          : [action.payload];
      }

      const deduplicatedList = mergedList
        .reduce((acc: any, room: any) => {
          if (!acc.some((r: any) => r.roomId === room.roomId)) acc.push(room);
          return acc;
        }, [])
        .sort((a: any, b: any) => new Date(b.updatedDate).getTime() - new Date(a.updatedDate).getTime());

      state.archiveChatRoomsList = {
        list: deduplicatedList,
        hasMore: action.payload.hasMore,
      };
    },
    setSearchChatRoomsList: (state, action) => {
      // Merge list with hasMore
      // const mergedList =
      //   state.searchChatRoomsList.list?.length > 0
      //     ? [...state.searchChatRoomsList.list, ...action.payload.list]
      //     : action.payload.list;

      // else {
      //   // Add single room if no hasMore or list exists
      //   mergedList = state.searchChatRoomsList.list
      //     ? [action.payload, ...state.searchChatRoomsList.list]
      //     : [action.payload];
      // }
      let mergedList;
      if (action.payload.list) {
        // Merge list with hasMore
        mergedList =
          state.searchChatRoomsList?.list?.length > 0
            ? [...state.searchChatRoomsList.list, ...action.payload.list]
            : action.payload.list;
      } else {
        // Add single room if no hasMore or list exists
        mergedList = state.searchChatRoomsList.list
          ? [action.payload, ...state.searchChatRoomsList.list]
          : [action.payload];
      }

      const deduplicatedList = mergedList
        .reduce((acc: any, room: any) => {
          if (!acc.some((r: any) => r.roomId === room.roomId)) acc.push(room);
          return acc;
        }, [])
        .sort((a: any, b: any) => new Date(b.updatedDate).getTime() - new Date(a.updatedDate).getTime());

      state.searchChatRoomsList = {
        list: deduplicatedList,
        hasMore: action.payload.hasMore,
      };
    },
    setActiveChatRoom: (state, action) => {
      state.activeChatRoom = action.payload;
    },
    updateProfessionalChatRoom: (state, action) => {
      state.professionalChatRoomsList = {
        list: state.professionalChatRoomsList?.list?.filter((item: any) => item?.roomId !== action.payload?.roomId),
        hasMore: state.professionalChatRoomsList.hasMore,
      };
    },
    updatePersonalChatRoom: (state, action) => {
      state.personalChatRoomsList = {
        list: state.personalChatRoomsList?.list?.filter((item: any) => item?.roomId !== action.payload?.roomId),
        hasMore: state.personalChatRoomsList.hasMore,
      };
    },
    updateArchivedChatRoom: (state, action) => {
      state.archiveChatRoomsList = {
        list: state.archiveChatRoomsList?.list?.filter((item: any) => item?.roomId !== action.payload?.roomId),
        hasMore: state.archiveChatRoomsList.hasMore,
      };
    },

    removeProfessionalChatRoom: (state, action) => {
      state.professionalChatRoomsList = {
        list: state.professionalChatRoomsList?.list?.filter((item: any) => item?.roomId !== action.payload?.roomId),
        hasMore: state.professionalChatRoomsList.hasMore,
      };
    },
    removePersonalChatRoom: (state, action) => {
      state.personalChatRoomsList = {
        list: state.personalChatRoomsList?.list?.filter((item: any) => item?.roomId !== action.payload?.roomId),
        hasMore: state.personalChatRoomsList.hasMore,
      };
    },
    removeInactiveChatRoom: (state, action) => {
      state.personalChatRoomsList = {
        list: state.inActiveChatRoomsList?.list?.filter((item: any) => item?.roomId !== action.payload?.roomId),
        hasMore: state.inActiveChatRoomsList.hasMore,
      };
    },
    removeSearchChatRoom: (state, action) => {
      state.searchChatRoomsList = {
        list: state.searchChatRoomsList?.list?.filter((item: any) => item?.roomId !== action.payload?.roomId),
        hasMore: state.searchChatRoomsList.hasMore,
      };
    },
    setSearchValueStored: (state, action) => {
      state.searchValueStored = action.payload;
    },
    setRoomDetails: (state, action) => {
      console.log(action.payload, "jjkjkjk");
      state.roomDetails = action?.payload;
    },
    setLoaderForRoomList: (state, action) => {
      state.loaderForRoomList = action.payload;
    },
    setDeleteInactiveChatModel: (state, action) => {
      state.deleteInactiveChatModel = action.payload;
    },
    setUpdatedRoomListen: (state, action) => {
      console.log(state.messages, action, "Checking");
      if (action?.payload?.roomStatus === "Active" && action?.payload?.roomType === "Single") {
        if (state.searchChatRoomsList?.list?.length) {
          state.searchChatRoomsList = {
            list: state.searchChatRoomsList?.list?.map((item: any) =>
              item?.roomId === action?.payload?.roomId ? { ...item, ...action?.payload } : item
            ),
            hasMore: state.searchChatRoomsList.hasMore,
          };
        } else {
          state.personalChatRoomsList = {
            list: state.personalChatRoomsList?.list?.map((item: any) =>
              item?.roomId === action?.payload?.roomId ? { ...item, ...action?.payload } : item
            ),
            hasMore: state.personalChatRoomsList.hasMore,
          };
        }
      }
      if (action?.payload?.roomStatus === "Active" && action?.payload?.roomType === "Group") {
        if (state.searchChatRoomsList?.list?.length) {
          state.searchChatRoomsList = {
            list: state.searchChatRoomsList?.list?.map((item: any) =>
              item?.roomId === action?.payload?.roomId ? { ...item, ...action?.payload } : item
            ),
            hasMore: state.searchChatRoomsList.hasMore,
          };
        } else {
          state.professionalChatRoomsList = {
            list: state.professionalChatRoomsList?.list?.map((item: any) =>
              item?.roomId === action?.payload?.roomId ? { ...item, ...action?.payload } : item
            ),
            hasMore: state.professionalChatRoomsList.hasMore,
          };
        }
      }
      if (action?.payload?.roomStatus === "Inactive") {
        if (state.searchChatRoomsList?.list?.length) {
          state.searchChatRoomsList = {
            list: state.searchChatRoomsList?.list?.map((item: any) =>
              item?.roomId === action?.payload?.roomId ? { ...item, ...action?.payload } : item
            ),
            hasMore: state.searchChatRoomsList.hasMore,
          };
        } else {
          state.inActiveChatRoomsList = {
            list: state.inActiveChatRoomsList?.list?.map((item: any) =>
              item?.roomId === action?.payload?.roomId ? { ...item, ...action?.payload } : item
            ),
            hasMore: state.inActiveChatRoomsList.hasMore,
          };
        }
      }
      if (action?.payload?.roomStatus === "Archive") {
        state.archiveChatRoomsList = {
          list: state.archiveChatRoomsList?.list?.map((item: any) =>
            item?.roomId === action?.payload?.roomId ? { ...item, ...action?.payload } : item
          ),
          hasMore: state.archiveChatRoomsList.hasMore,
        };
      }
    },
    setClearArchiveChatRoom: (state) => {
      state.archiveChatRoomsList = {
        list: [],
        hasMore: false,
      };
    },
    setClearSearchChatRoom: (state) => {
      state.searchChatRoomsList = {
        list: [],
        hasMore: false,
      };
    },

    setClearProfessionalChatRoom: (state) => {
      state.professionalChatRoomsList = {
        list: [],
        hasMore: false,
      };
    },
    setClearPersonalChatRoom: (state) => {
      state.personalChatRoomsList = {
        list: [],
        hasMore: false,
      };
    },
    setClearInactiveChatRoom: (state) => {
      state.inActiveChatRoomsList = {
        list: [],
        hasMore: false,
      };
    },
    setDeleteChatRoom: (state) => {
      state.activeChatRoom = null;
      state.roomDetails = {};
    },
    setClearChatRoom: (state) => {
      state.professionalChatRoomsList = [];
      state.personalChatRoomsList = [];
      state.inActiveChatRoomsList = [];
      state.archiveChatRoomsList = [];
      state.searchChatRoomsList = [];
      state.searchValueStored = "";
      state.activeChatRoom = null;
      state.loaderForRoomList = false;
      state.roomDetails = {};
    },
  },
  extraReducers: () => {},
});

export const {
  setActiveChatRoom,
  setLoaderForRoomList,
  setProfessionalChatRoomsList,
  setPersonalChatRoomsList,
  setInactiveChatRoomsList,
  setArchiveChatRoomsList,
  setSearchChatRoomsList,
  setSearchValueStored,
  setRoomDetails,
  setClearChatRoom,
  setClearArchiveChatRoom,
  updateProfessionalChatRoom,
  updatePersonalChatRoom,
  updateArchivedChatRoom,
  setClearSearchChatRoom,
  setClearProfessionalChatRoom,
  setClearPersonalChatRoom,
  setClearInactiveChatRoom,
  setDeleteChatRoom,
  removeProfessionalChatRoom,
  removePersonalChatRoom,
  removeInactiveChatRoom,
  setDeleteInactiveChatModel,
  setUpdatedRoomListen,
  removeSearchChatRoom,
} = chatRoomSlice.actions;

export default chatRoomSlice.reducer;
/* eslint-enable @typescript-eslint/no-explicit-any */
