// services/sockets/socket.ts

export let socket: WebSocket | null = null;

// Export a function to initialize the WebSocket
export const initializeWebSocket = (userId: string, deviceId: string): WebSocket | null => {
  if (!socket) {
    socket = new window.WebSocket(`${process.env.NEXT_PUBLIC_SOCKET_DOMAIN}?userId=${userId}&deviceId=${deviceId}`);
  }
  return socket;
};

// Export a function to disconnect the WebSocket
export const disconnectWebSocket = () => {
  if (socket) {
    socket?.close();
    socket = null;
  }
};

// Optionally, export the current WebSocket if already initialized
export const getWebSocket = (): WebSocket | null => socket;
